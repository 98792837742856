var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form-model',_vm._b({ref:"form",attrs:{"model":_vm.form}},'a-form-model',_vm.$formItemLayout,false),[_c('a-form-model-item',{attrs:{"label":"角色名称：","prop":"roleName","rules":{
      required: true,
      message: '请输入角色名称',
      trigger: 'blur'
    }}},[_c('a-input',{attrs:{"maxLength":25},model:{value:(_vm.form.roleName),callback:function ($$v) {_vm.$set(_vm.form, "roleName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.roleName"}})],1),_c('a-form-model-item',{attrs:{"label":"角色描述：","prop":"roleDescribe","rules":{
      required: false,
      message: '请输入角色描述',
      trigger: 'blur'
    }}},[_c('a-input',{attrs:{"maxLength":25},model:{value:(_vm.form.roleDescribe),callback:function ($$v) {_vm.$set(_vm.form, "roleDescribe", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.roleDescribe"}})],1),_c('a-form-model-item',{attrs:{"label":"分配权限：","rules":{
      required: true,
      message: '请分配权限',
      trigger: 'blur'
    },"prop":"menuSet"}},[_c('a-card',[(_vm.treeData.length > 0)?_c('a-tree',{attrs:{"tree-data":_vm.treeData,"default-expand-all":false,"checkable":"","replaceFields":{ title: 'menuName', key: 'id' }},on:{"check":_vm.onCheck},model:{value:(_vm.checkedKeys),callback:function ($$v) {_vm.checkedKeys=$$v},expression:"checkedKeys"}}):_vm._e()],1)],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"64px"},attrs:{"label":"角色类型：","rules":{
      required: true,
      message: '请分配权限',
      trigger: 'blur'
    },"prop":"roleType"}},[_c('a-radio-group',{model:{value:(_vm.form.roleType),callback:function ($$v) {_vm.$set(_vm.form, "roleType", $$v)},expression:"form.roleType"}},_vm._l((_vm.statusOptions),function(statusInfo){return _c('a-radio',{key:statusInfo.value,attrs:{"value":statusInfo.value}},[_vm._v(" "+_vm._s(statusInfo.label)+" ")])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }