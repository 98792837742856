import request from '@/utils/request'
const roleApi = {
  list: '/role',
  create: '/role',
  update: id => `/role/${id}`,
  detail: id => `/role/${id}`,
  remove: id => `/role/${id}`,
  roleOptions: '/role/allByUser'
}
export function list(parameter) {
  return request({
    url: roleApi.list,
    method: 'get',
    params: parameter
  })
}
export function create(parameter) {
  return request({
    url: roleApi.create,
    method: 'post',
    data: parameter
  })
}
export function update(parameter) {
  const { id } = parameter
  delete parameter.id
  return request({
    url: roleApi.update(id),
    method: 'put',
    data: parameter
  })
}
export function detail(parameter) {
  return request({
    url: roleApi.detail(parameter),
    method: 'get'
  })
}
export function remove(parameter) {
  return request({
    url: roleApi.remove(parameter),
    method: 'delete'
  })
}
export function roleOptions() {
  return request({
    url: roleApi.roleOptions,
    method: 'get'
  })
}
