import request from '@/utils/request'
const menuApi = {
  create: '/menu',
  update: id => `/menu/${id}`,
  detail: id => `/menu/${id}`,
  remove: id => `/menu/${id}`,
  menuOptions: '/menu/all'
}
export function create(parameter) {
  return request({
    url: menuApi.create,
    method: 'post',
    data: parameter
  })
}
export function update(parameter) {
  const { id } = parameter
  delete parameter.id
  return request({
    url: menuApi.update(id),
    method: 'put',
    data: parameter
  })
}
export function detail(parameter) {
  return request({
    url: menuApi.detail(parameter),
    method: 'get'
  })
}
export function remove(parameter) {
  return request({
    url: menuApi.remove(parameter),
    method: 'delete'
  })
}
export function menuOptions() {
  return request({
    url: menuApi.menuOptions,
    method: 'get'
  })
}
