<template>
  <a-form-model ref="form" :model="form" v-bind="$formItemLayout">
    <a-form-model-item
      label="角色名称："
      prop="roleName"
      :rules="{
        required: true,
        message: '请输入角色名称',
        trigger: 'blur'
      }"
    >
      <a-input v-model.trim="form.roleName" :maxLength="25" />
    </a-form-model-item>
    <a-form-model-item
      label="角色描述："
      prop="roleDescribe"
      :rules="{
        required: false,
        message: '请输入角色描述',
        trigger: 'blur'
      }"
    >
      <a-input v-model.trim="form.roleDescribe" :maxLength="25" />
    </a-form-model-item>
    <a-form-model-item
      label="分配权限："
      :rules="{
        required: true,
        message: '请分配权限',
        trigger: 'blur'
      }"
      prop="menuSet"
    >
      <a-card>
        <a-tree
          v-if="treeData.length > 0"
          v-model="checkedKeys"
          :tree-data="treeData"
          :default-expand-all="false"
          checkable
          @check="onCheck"
          :replaceFields="{ title: 'menuName', key: 'id' }"
        >
        </a-tree>
      </a-card>
    </a-form-model-item>
    <a-form-model-item
      label="角色类型："
      :rules="{
        required: true,
        message: '请分配权限',
        trigger: 'blur'
      }"
      prop="roleType"
      style="margin-bottom: 64px"
    >
      <a-radio-group v-model="form.roleType">
        <a-radio v-for="statusInfo in statusOptions" :key="statusInfo.value" :value="statusInfo.value">
          {{ statusInfo.label }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { cloneDeep } from 'lodash'
import { PERMISSION_COMMON_STATUS } from '@/store/dictionary'
import * as menuApi from '@/api/menu'

export default {
  components: {},
  data() {
    return {
      form: {
        menuSet: [],
        roleName: '',
        roleDescribe: '',
        roleType: ''
      },
      treeData: [],
      checkedKeys: [],
      selectedKeys: [],
      statusOptions: PERMISSION_COMMON_STATUS
    }
  },
  mounted() {},
  methods: {
    getTreeChildren(data) {
      data &&
        data.map(item => {
          if (item.children && item.children.length > 0) {
            this.getTreeChildren(item.children)
          } else {
            this.childrenSet.push(item.id)
          }
        })
      return this.childrenSet
    },
    compareItem(all_data, child_data) {
      const uniqueChild = []
      for (const i in child_data) {
        for (const k in all_data) {
          if (all_data[k] === child_data[i]) {
            uniqueChild.push(all_data[k])
          }
        }
      }
      return uniqueChild
    },
    initData(form) {
      menuApi.menuOptions().then(res => {
        this.treeData = res.data
        this.form = Object.assign({}, this.form, form)
        this.childrenSet = []
        this.getTreeChildren(res.data)
        this.checkedKeys = this.compareItem(this.form.menuSet, this.childrenSet)
      })
    },
    validate() {
      const {
        $refs: { form }
      } = this
      return new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
    },
    formatForm() {
      try {
        const ret = cloneDeep(this.form)
        return { ...ret }
      } catch {}
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onCheck(checkedKeys, node) {
      const { halfCheckedKeys } = node
      this.form.menuSet = [...checkedKeys, ...halfCheckedKeys]
    }
  }
}
</script>

<style lang="less" scoped></style>
