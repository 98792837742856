<template>
  <a-card>
    <div slot="title">编辑角色</div>

    <basis-form ref="basis" />
    <a-row>
      <a-col v-bind="$formItemLayoutWithoutLabel.wrapperCol">
        <a-button type="primary" shape="round" style="width:160px" @click="onSubmit">
          确认
        </a-button>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import BasisForm from './components/BasisForm'
import * as roleApi from '@/api/role'
export default {
  name: 'PermissionRoleEdit',
  components: {
    BasisForm
  },
  watch: {
    $route(newValue, oldValue) {
      if (this.$route.name === 'PermissionRoleEdit') {
        this.initData()
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { id }
        }
      } = this
      roleApi.detail(id).then(res => {
        const { id, roleName, roleDescribe, roleType, menuSet } = res.data
        const _menuSet = []
        menuSet.forEach(e => {
          _menuSet.push(e.id)
        })
        this.$refs.basis.initData({
          id,
          menuSet: _menuSet,
          roleName,
          roleDescribe,
          roleType
        })
      })
    },
    onSubmit() {
      const {
        $refs: { basis },
        $notification
      } = this
      basis.validate().then(valid => {
        if (valid) {
          this.loading = true
          try {
            const { formatForm } = basis
            const form = formatForm()
            const { id } = form
            if (id) {
              roleApi
                .update({ ...form })
                .then(res => {
                  $notification['success']({ message: '操作成功' })
                  this.close()
                })
                .finally(() => (this.loading = false))
            }
          } catch {
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    close() {
      const { current, pageSize } = this.$route.query
      this.$router.push({ name: 'PermissionRoleList', query: { current, pageSize } })
    }
  }
}
</script>

<style lang="less" scoped></style>
